<template>
  <div class="register-page">
    <img src="~@/assets/login-bg.png" alt="" class="login-bg" />
    <div class="page-main">
      <a-spin :spinning="loadingForm">
        <a-form
          ref="formRef"
          :model="form"
          class="login-form box-shadow-1"
          @finish="handleSubmit"
        >
          <div class="logon-title flex">用户注册</div>
          <a-alert
            v-if="isLoginError"
            type="error"
            show-icon
            style="margin-bottom: 24px"
            :message="errorMessage"
          />
          <a-form-item
            name="user_phone"
            :rules="{ required: true, message: '请输入手机号' }"
          >
            <a-input
              size="large"
              v-model:value="form.user_phone"
              placeholder="请输入手机号"
            >
              <template #prefix>
                <img src="~@/assets/phone.png" alt="" class="mr10" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="sms_code"
            :rules="{ required: true, message: '请输入验证码' }"
          >
            <a-input
              size="large"
              v-model:value="form.sms_code"
              placeholder="请输入验证码"
            >
              <template #prefix>
                <img src="~@/assets/password.png" alt="" class="mr10" />
              </template>
              <template #suffix>
                <span v-show="show" class="send" @click="sendSmscode"
                  >获取验证码</span
                >
                <span v-show="!show" class="send-activity"
                  >{{ secondcount }}s</span
                >
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="user_password"
            :rules="{ required: true, message: '请输入密码' }"
          >
            <a-input
              size="large"
              v-model:value="form.user_password"
              type="password"
              placeholder="请输入密码"
            >
              <template #prefix>
                <img src="~@/assets/password.png" alt="" class="mr10" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            name="user_repeat_password"
            :rules="{ required: true, message: '请确认密码' }"
          >
            <a-input
              size="large"
              v-model:value="form.user_repeat_password"
              type="password"
              placeholder="请确认密码"
            >
              <template #prefix>
                <img src="~@/assets/password.png" alt="" class="mr10" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item class="login-button-box">
            <a-button
              block
              size="large"
              type="primary"
              html-type="submit"
              class="login-button button-color-blue"
              :loading="loadingBtn"
              @click="handleSubmit"
            >
              注册
            </a-button>
          </a-form-item>
          <div>
            <a-checkbox v-model:checked="checked">我已阅读并同意</a-checkbox>
            <span style="color: #1276cb; cursor: pointer" @click="readPrivacy"
              >《服务政策与隐私协议》</span
            >
          </div>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from "@vue/runtime-core";
import { message, notification } from "ant-design-vue";
import url from "../utils/url";
// import ls from "@/utils/local-storage";
import { useStore } from "vuex";
import { sendCodeSms } from "@/api/main.js";

export default defineComponent({
  name: "Register",
  components: {},
  setup() {
    const store = useStore();
    const formRef = ref();
    const pageData = reactive({
      isLoginError: false,
      errorMessage: null,
      loginBtn: false,
      form: {
        user_phone: null,
        sms_code: null,
        user_repeat_password: null,
        user_password: null
      },
      loadingBtn: false,
      loadingForm: false,
      secondcount: "",
      show: true,
      timer: null,
      checked: false
    });
    const sendSmscode = () => {
      const TIME_COUNT = 60;
      if (!pageData.form.user_phone) {
        return message.error("请输入手机号码！");
      }
      sendCodeSms({ user_phone: pageData.form.user_phone })
        .then(res => {
          if (res.code == 200) {
            if (!pageData.timer) {
              pageData.secondcount = TIME_COUNT;
              pageData.show = false;
              pageData.timer = setInterval(() => {
                if (
                  pageData.secondcount > 0 &&
                  pageData.secondcount <= TIME_COUNT
                ) {
                  pageData.secondcount--;
                } else {
                  pageData.show = true;
                  clearInterval(pageData.timer);
                  pageData.timer = null;
                }
              }, 1000);
            }
            3;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {});
    };
    const readPrivacy = () => {
      url.navigateTo("/privacy");
    };
    const handleSubmit = e => {
      if (!pageData.checked) {
        return message.error("请阅读并同意《服务政策与隐私协议》");
      }
      if (pageData.form.user_password != pageData.form.user_repeat_password) {
        return message.error("两次输入的密码不一致！");
      }
      e.preventDefault();
      pageData.loadingBtn = true;
      pageData.loadingForm = true;
      formRef.value
        .validate()
        .then(res => {
          console.log(res);
          store
            .dispatch("LOGIN", { ...res, loginMehod: "tab1", isRegister: 1 })
            .then(() => {
              notification.success({
                message: "注册成功",
                duration: 1.5,
                description: "正在跳转页面中，请稍后……",
                onClose: () => {
                  url.navigateTo("/");
                  // location.reload();
                  pageData.loadingForm = false;
                }
              });
            })
            .catch(res2 => {
              console.log("res2", res2);
              pageData.isLoginError = true;
              pageData.errorMessage = res2.msg;
              pageData.loadingForm = false;
            })
            .finally(() => {
              pageData.loadingBtn = false;
            });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };

    return {
      ...toRefs(pageData),
      handleSubmit,
      formRef,
      sendSmscode,
      readPrivacy
    };
  }
});
</script>

<style lang="less">
.register-page {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 602px;
  .login-bg {
    display: block;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .page-main {
    width: 1200px;
    position: absolute;
    height: 600px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row-reverse;
  }
  .login-form {
    width: 420px;
    height: 530px;
    background: #fff;
    padding: 40px 30px;
    border-radius: 20px;
    margin-top: 35px;
    box-sizing: border-box;
    .mr10 {
      margin-right: 10px;
    }
    .logon-title {
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: 700;
    }
    .login-button {
      width: 361px;
      height: 67px;
      background-color: #1276cb;
      border-radius: 10px;
      font-size: 20px;
      // margin-top: 10px;
    }
    .ant-input-affix-wrapper-focused {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #dfdfdf;
    }
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #ff4d4f;
    }
    .ant-input-affix-wrapper {
      resize: none;
      border: none;
      border-bottom: 1px solid #dfdfdf;
      height: 45px;
    }
    .ant-input {
      font-size: 18px;
    }
    .send {
      color: #1276cb;
      font-size: 18px;
      cursor: pointer;
    }
    .send-activity {
      color: #212531;
      opacity: 0.7;
    }
  }
}
</style>
